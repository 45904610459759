import React, { useState, useEffect, useCallback} from 'react';
import './App.css';

import Button from '@mui/material/Button';
import { createSvgIcon } from '@mui/material/utils';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid2';

import {
    GridRowModes,
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
    GridActionsCellItem,
    GridRowEditStopReasons,
    gridPaginatedVisibleSortedGridRowIdsSelector,
    gridSortedRowIdsSelector,
    gridExpandedSortedRowIdsSelector,
    useGridApiContext,
    gridClasses,
    useGridApiRef,
    
} from '@mui/x-data-grid';

import { useSelector } from 'react-redux'

import RecordEdit from './RecordEdit'

import {
    getLeads,
    updateLead,
    deleteLead,
    getPostodeGroupLookups,
    getdatausers
} from './APIS/leadsAPI'

import {getAllDDs, getDD, getlkuptables} from './APIS/datadictAPI'
import DownloadCSV from './components/DownloadCSV';

const getRowsFromCurrentPage = ({ apiRef }) =>{
  gridPaginatedVisibleSortedGridRowIdsSelector(apiRef);
}

const getUnfilteredRows = ({ apiRef }) => {
  gridSortedRowIdsSelector(apiRef)
};

const getFilteredRows = ({ apiRef }) => gridExpandedSortedRowIdsSelector(apiRef);

const ExportIcon = createSvgIcon(
  <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" />,
  'SaveAlt',
);


function CustomToolbar() {
  const apiRef = useGridApiContext();

  const handleExport = (options) => apiRef.current.exportDataAsCsv(options);

  const buttonBaseProps = {
    color: 'primary',
    size: 'small',
    startIcon: <ExportIcon />,
  };
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector
        slotProps={{ tooltip: { title: 'Change density' } }}
      />
      <Box sx={{ flexGrow: 1 }} />
      <Button
        {...buttonBaseProps}
        onClick={() => handleExport({ getRowsToExport: getRowsFromCurrentPage })}
      >
        Current page rows
      </Button>
      <Button
        {...buttonBaseProps}
        onClick={() => handleExport({ getRowsToExport: getFilteredRows })}
      >
        Filtered rows
      </Button>
      <Button
        {...buttonBaseProps}
        onClick={() => handleExport({ getRowsToExport: getUnfilteredRows })}
      >
        All row
      </Button>
    </GridToolbarContainer>
  );
}





function GridView() {
  const[rows,setRows]=useState([])
  const [selrow,setSelectedRow]=useState({})
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 25,
    page: 0,
  });
  const [isloading,setIsLoading] = useState(false)
  const [leadset, setLeadSet] = useState("")
  const [leadsets, setLeadSets] = useState([])
  const [openeditor, setOpenEditor] = useState(false)
  const [selectedid, setSelectedId] = useState(null)
  const [griddensity, setGridDensity] = useState('compact')
  const [rowtodelete,setRowToDelete]=useState(null)
  const [confirmdelete,setConfirmDelete] = useState(false)
  const [columnsloaded,setColumnsLoaded]=useState(false)
  const [columns,setColumns] = useState([])
  const auth = useSelector(state => state.auth.auth)
  const [refreshPage,setRefreshPage] = useState(false)
  const [refreshtimerid, setRefreshTimerid]=useState(null)
  const [lasttimeinterval, setLastTimerInterval]= useState(null)
  //const [postcodegroups,setPostcodeGroups] = useState([])
  const [postcodelookups,setPostcodeLookup]= useState([])
  const [sxclasses,setSXClasses]= useState('')
  const [selectedrowcount, setSelectedrowcount] = useState(0)

  // State from Redux
  const systables = useSelector((state) => state.systables)
  const [selecteddatauser, setSelectedDataUser] = useState(null)
  const [datausers, setDataUsers] = useState([])
  const [disableoutput, setDisableOutput] = useState(true)
  const [OutputMethods,setOutputMethods ] = useState([])  
  const [selectedoutputmethod, setSelectedOutputMethod]=useState("")

  const refreshinterval = useSelector((state) => {
    return state.settings.refreshinterval
  })

  const gridfontsize = useSelector((state) => {
    return state.settings.gridfontsize
  })

  const apiGridRef = useGridApiRef();

  console.log(`refreshinterval set to.... ${refreshinterval}`)


const setupPostcodeGroups = async (data) => {
  let datauser = ''
  let tmpsxclasses = {
    '--DataGrid-overlayHeight': '400px', 
    margin: '10px 10px 10px 10px',
    fontSize: gridfontsize
  }

  for(let i=0; i<data.length; i++) {
    const item = data[i]
    if(item.datauser !== datauser) {
      datauser = item.datauser
      tmpsxclasses[`.${gridClasses.cell}.${item.code}`] = {backgroundColor: `${item.bgcolor}`, color: `${item.color}`}
    }
  }
  console.log(`tmpsxclasses set to`)
  console.log(tmpsxclasses)
  setSXClasses(tmpsxclasses)
}


  const handleViewClick = (id) => {
      const srch = rows.find(item => item.id === id)
      if(srch) {
        setSelectedRow(srch)
        setSelectedId(srch._id)
        setOpenEditor(true)
      }
  }

  const hideEditor = () => { setOpenEditor(false) }
  const onPageSizeChange = (gridmodel, details) => {
    console.log(gridmodel)
    console.log(details)
    setPaginationModel(gridmodel)
  }

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    setRowToDelete(id)
    setConfirmDelete(true)
  };

  const confirmDelete = async () => {
    const id = rowtodelete
    const srch = rows.find((row) => row.id === id)
    if(srch) {
      setRows(rows.filter((row) => row.id !== id)); 
      await deleteLead(auth.token, srch._id);
    }
    setConfirmDelete(false)
    setRowToDelete(null)
  }

  const cancelDelete = (id) => {
    setConfirmDelete(false)
    setRowToDelete(null)
  }

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = async (newRow) => {
    console.log('processNewRowUpdate')
    console.log(newRow)
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));

    // Update in Mongo DB
    await updateLead(auth.token, newRow);
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    console.log(`newRowModesModel set to ${newRowModesModel}`)
    setRowModesModel(newRowModesModel);
  };



  const actions = [
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        const canedit = auth.security.canedit??false

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        if(canedit)
        return [
          <GridActionsCellItem
            icon={<VisibilityIcon />}
            label="Edit / View"
            className="textPrimary"
            onClick={() => handleViewClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];

        return [
          <GridActionsCellItem
            icon={<VisibilityIcon />}
            label="Edit / View"
            className="textPrimary"
            onClick={() => handleViewClick(id)}
            color="inherit"
          />
        ]
      },
    }];

    const getReduxSysTable = useCallback(async (tablename) => {
      switch(tablename) {
        case 'users':
          return systables.users;
        case 'titles':
          return systables.titles;
        case 'statuses':
          return systables.statuses;
        default: return [];
      }
    },[systables.users,systables.titles,systables.statuses])
  
  

    // Get All of the leadsets Available
    useEffect(()=>{
      // fetch leadsets
      async function GetLeadSets() {
        const ret = await getAllDDs(auth.token)
        if(ret.status) {
          const lsets = await ret.data.map(item => {return {_id: item._id, leadset: item.leadset, description:item.description} })
          setLeadSets(lsets)
        }

        const pclookups = await getPostodeGroupLookups(auth.token)
        if(pclookups.status) {
          setPostcodeLookup(pclookups.data)
          await setupPostcodeGroups(pclookups.data)
        }

        const rdatausers = await getdatausers(auth.token)
        console.log(`DataUsers returmed.`)
        console.log(rdatausers)
        if(rdatausers.status) {
          setDataUsers(rdatausers.data)
        }
      }
      GetLeadSets();
    },[auth.token])

    useEffect(()=> {
      const getData = async () => {
        setIsLoading(true)
        const ret = await getLeads(auth.token,leadset, 20000)
        if(ret.status) {
          setRows(ret.data)
        }

        // Now fill in any Select Items
        if(columns.length ===0 && !columnsloaded) {
          const ret = await getDD(auth.token, leadset)
          if(ret.status) {
            const ddict = ret.data
            console.log(`Gte Columns from teh Data Dictionary`)
            console.log(ddict.fields)
            let tmpcolumns = JSON.parse(JSON.stringify([...ddict.fields])) 
            for(let i=0; i<tmpcolumns.length; i++) {
              if(tmpcolumns[i].type==='singleSelect') {
                const lkupflds = tmpcolumns[i].valueLookup
                var lkupitems = null
                if(lkupflds.leadset === 'system') {
                  lkupitems = await getReduxSysTable(lkupflds.table)
                }else {
                  const lkup = await getlkuptables(auth.token,lkupflds.leadset, lkupflds.table)
                  if(lkup.status && typeof lkup.data !== "undefined" && Array.isArray(lkup.data) ) 
                    lkupitems = lkup.data
                }
                if(lkupitems && Array.isArray(lkupitems) ) {
                  var opts = []
                  lkupitems.forEach((entry) => {
                    opts.push(entry)
                  });

                  tmpcolumns[i].valueOptions = opts
                }
              }
              if(typeof tmpcolumns[i].editable !== "undefined" && typeof tmpcolumns[i].editable ==="string" && tmpcolumns[i].editable.substring(0,4) === "auth")
                tmpcolumns[i].editable = eval(tmpcolumns[i].editable)
              
              if(typeof tmpcolumns[i].valueGetter !== "undefined")
                tmpcolumns[i].valueGetter = Function("value",tmpcolumns[i].valueGetter)
            }
            setColumns(tmpcolumns)
            setColumnsLoaded(true)
          }
        }
        setIsLoading(false)
      }
      if(leadset > "" )
        getData()
    },[columns,auth.token,refreshPage, leadset,columnsloaded, getReduxSysTable ])
  

  const refreshData = useCallback(() => {
      setRefreshPage(prevVal => !prevVal)
  },[]);

  useEffect(() => {
    if(refreshinterval === 0) {
      if(refreshtimerid !== null) {
        // Timer is set so we need to clear it
        clearInterval(refreshtimerid)
        setRefreshTimerid(null)
        setLastTimerInterval(refreshinterval)
      }
    }else {
      if(refreshinterval > 0 ) {
        if(refreshtimerid === null) {
          // No Timer is set to create a new one
          const timerid = setInterval(refreshData, (refreshinterval * 60000))
          setRefreshTimerid(timerid)
          setLastTimerInterval(refreshinterval)
        }else {
          // If the interval has changed - Clear existing timer and create a new one.
          if(refreshinterval !== lasttimeinterval) {
            clearInterval(refreshtimerid)
            const timerid = setInterval(refreshData, (refreshinterval * 60000))
            setRefreshTimerid(timerid)
            setLastTimerInterval(refreshinterval)
          }
        }
      }
    }
  },[refreshinterval,refreshtimerid,lasttimeinterval,refreshData])

  useEffect(()=>{
    let tmpsxclasses = sxclasses;
    if(typeof tmpsxclasses.fontSize !== "undefined") {
      console.log(tmpsxclasses)
      console.log(gridfontsize)
      tmpsxclasses.fontSize = `${gridfontsize}`;
      setSXClasses(tmpsxclasses)
    }
  },[gridfontsize,sxclasses])

  const handleLeadSetChange = (e) => {
    setColumns([])
    setColumnsLoaded(false)
    setLeadSet(e.target.value)
  }

  const handleDataUserChange = (e) => {
    setDisableOutput(false)
    const datauser = e.target.value??"" 
    setSelectedDataUser(e.target.value)
    if(datauser==="") {
      setDisableOutput(true)
      setOutputMethods("")
    }else {
      const srch = datausers.find(item => item._id === datauser)
      if(srch) {
        let om = srch.method.split(",")
        console.log(`${srch.method}`)
        console.log(om)
        setOutputMethods(om)
      }
    } 

  }

  const handleSelRow = (rows, details) => {
    console.log(`in handleSelRow`)
    console.log(rows)
    console.log(details)
    const selrows = rows
    if(Array.isArray(selrows))
      setSelectedrowcount(selrows.length)
  }
  const handleOutputMethod = (e) => {
    setSelectedOutputMethod(e.target.value)
  }

  const handleOutputData = () => {
    console.log('Output Data Clicked')
    console.log(apiGridRef);
    const selrows = apiGridRef.current.getSelectedRows()
    switch(selectedoutputmethod) {
      case 'CSV':
        OutputCSVFile(selrows);
        break;
      case 'EMAIL':
        OutputEmails(selrows);
        break;
      case 'HUBSPOT':
        OutputHubSpot(selrows);
        break;
      default:
        break;
    }

  }

  const OutputCSVFile = (data) => {
    console.log(`in OutputCSV`)
    console.log(data)
    const arrdata = Array.from(data, ([key, value]) => ({ key, value }));


    const srch = datausers.find(item => item._id === selecteddatauser )
    let csvdata = `"ID","First Name","Surname","Phone","Email","Postcode","Home Ownership","LPA For","LPA Type"\n`
    const fields = ["_id","firstname","lastname","phone","email","postcode","userfield1","userfield2","userfield3"]
    if(srch ) {
      const dt = new Date()
      const dateext = dt.getDate().toString().padStart(2,'0') + (dt.getMonth()+1).toString().padStart(2,'0') + dt.getFullYear().toString() + '_' + dt.getHours().toString().padStart(2,'0') + dt.getMinutes().toString().padStart(2,'0') + dt.getSeconds().toString().padStart(2,'0')
      const filename = srch.name.toLowerCase().replaceAll(" ","_") + dateext + '.csv'

      arrdata.map(item => {
        var csvtext = ""
        fields.map(field => {
          csvtext += `"${item.value[field]}",`  
        })
        csvtext = csvtext.substring(0,csvtext.length-1) + "\n"
        csvdata += csvtext
      })
      console.log(filename)
      console.log(csvdata);
      DownloadCSV(csvdata,filename,"text/csv")
    }

  }

  const OutputEmails = (data) => {

  } 

  const OutputHubSpot = (data) => {

  }
  //if(isloading) 
  //  return (<h4>Loading Data....</h4>)
  const onDensityChange = (e) => {
    setGridDensity(e)
  }
  if(!isloading)
    return (
    <Paper sx={{ width: '100%', textAlign: 'center'}}>
      {openeditor && <RecordEdit open={openeditor} id={selectedid} data={selrow} cols={columns} setOpen={hideEditor} onUpdate={processRowUpdate} /> }
      <FormControl sx={{ m: 1, minWidth: 300 }}>
        <InputLabel id="lbl-leadset-select">Select Required Lead set</InputLabel>
        <Select           
          labelId="lbl-leadset-select"
          id="leadset-select"
          value={leadset}
          onChange={handleLeadSetChange}
          label="Select Leadset"
          size="small"
          autoWidth
        >
          {leadsets.map((item,index) => {
            return(<MenuItem key={`dset_${index}`} value={item.leadset} >{item.description}</MenuItem>)
          })}
        </Select>
      </FormControl>

      {columnsloaded && leadset &&

        <Box
          sx={{
            height: '700px',
            maxHeight: '700px',
            width: '100%',
          }}
        >
          <DataGrid
            apiRef={apiGridRef}
            sx={sxclasses}
            ignoreDiacritics={true}
            paginationMode="client"
            onPaginationModelChange={onPageSizeChange}
            rows={rows}
            columns={[...columns,...actions]}
            loading={isloading}
            density={griddensity}
            onDensityChange={onDensityChange}
            onRowSelectionModelChange={handleSelRow}
            initialState={{ 
              pagination: { rowCount: -1 },   
              sorting: {
                sortModel: [{ field: 'created', sort: 'desc' }],
              },
              columns: {    
                columnVisibilityModel: {
                  // Hide columns  the other columns will remain visible
                  _id: false,
                  downloaddate: false,
                  leadset: false,
                  datauser: false
                } 
              }
            }}
            pageSizeOptions={[5,10,20,25,50,100]}
            paginationModel = {paginationModel}
            checkboxSelection={true}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            autosizeOnMount={true}
            slots={{ toolbar: CustomToolbar }}
            getCellClassName={(params) =>  {
              if(params.field === 'postcode' && params.value > '') {
                const pcsrch = postcodelookups.find(item => item.postcode === params.value.substring(0,2).toLowerCase())

                if(pcsrch) {
                  return pcsrch.code  
                }          
            }
            return '';
          }}
          />
          </Box>
    }
    {columnsloaded && leadset &&
      <Grid container spacing={0}>
        <Grid size={5} className="aligncomponents">
          <FormControl size="small" sx={{ m: 1, minWidth: 200 }}>
            <FormHelperText>Select Output User</FormHelperText>
            <Select
            className="smallselfont"      
              id="datauser-select"
              value={selecteddatauser??""}
              onChange={handleDataUserChange}
              displayEmpty
              size="small"
              autoWidth
            ><MenuItem className='smallselfont' key='dsetempty' value="">None</MenuItem>
              {Array.isArray(datausers) && datausers.map((datauser,index) => {
                return(<MenuItem className='smallselfont' key={`dset_${index}`} value={datauser._id} >{datauser.name}</MenuItem>)
              })}
            </Select>

          </FormControl>
          {Array.isArray(OutputMethods) && OutputMethods.length > 0 && 
            <FormControl size="small" sx={{ m: 1, minWidth: 100 }}>
              <FormHelperText>Select Output Method</FormHelperText>
              <Select           
                className="smallselfont" 
                id="outputmethod-select"
                value={selectedoutputmethod??"CSV"}
                onChange={handleOutputMethod}
                size="small"
                displayEmpty
                autoWidth
              >
              {OutputMethods.map((item,index)=> {
                return(<MenuItem className='smallselfont' key={`omethod_${index}`} value={item} >{item}</MenuItem>)
              })}
              </Select>
            </FormControl>
          }
          <FormControl size="small" sx={{ m: 1, minWidth: 100 }}>
            <FormHelperText>{selectedrowcount} Rows Selected</FormHelperText>
            <Button variant="outlined" size="small" disabled={disableoutput} onClick={handleOutputData}>Output Data</Button>
          </FormControl>
        </Grid>
        <Grid size={2} className="aligncomponents">
          <Button variant="outlined" size="small" onClick={()=>{setRefreshPage(prevVal => !prevVal)}} >Refresh Data</Button>
        </Grid>
        <Grid size={5}></Grid>
      </Grid>
  }
  </Paper>
  );
}

export default GridView;
