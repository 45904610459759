import React,{useState} from "react";
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';

import { styled } from '@mui/system';
import { withStyles } from '@mui/styles';

import logo from './assets/logo.png'

import { useDispatch } from 'react-redux'
import { addauth } from './slices/authSlice.js'
import {APILogon} from './APIS/userAPI'

const StyledButton = styled(Button)(
    ({ theme }) => `
      background-color: ${theme.palette.mode === 'dark' ? '#daf1f8' : '#daf1f8'};
      color: ${theme.palette.mode === 'dark' ? '#ffffff' : '#000000'};
      ':hover': {
                backgroundColor: '#daffff',
                color: '#ffffff'
        }
    `,
  );

const styles = {
    logonForm: {
        width: 300,
        height: 300,
        marginLeft: 'auto',
        marginRight: 'auto',
        border: '2px solid #daf1f8',
        textAlign: 'center',
        marginTop: 100,
        paddingTop: 25
    },
    label: { marginTop: 20 },
    inputctl: { marginBottom: 10 },
    logo: { marginBottom: 20 }
}



const Logon = (props) => {
    const id = props._id ? props._id : 0
    const classes = props.classes
    const [username, setUsername] = useState('')
    const [password, setPassword]  = useState('')
    const [error, setError ]= useState('')
    const dispatch = useDispatch()


    const DoLogon = async () => {
        const ret = await APILogon(username,password)
        if(!ret.status ) {
            setError(ret.err)
            return
        }

        if(typeof ret.err !== 'undefined' && ret.err > "" ) {
            setError(ret.err)
            return
        }
        const data = ret.data
        if(data.token && data.name && data.email === username){
            // Update our Redux Store so we have security access throughout the app.
            const loggedon = {_id: data._id, token: data.token, user: data.name, email: data.email, 
                security: {
                    canview: data.canview, 
                    canedit: data.canedit, 
                    candownload: data.candownload,
                    canupdate: data.canupdate,
                    canupdatetitle: data.canupdatetitle,
                    canupdatefirst: data.canupdatefirst,
                    canupdatelast: data.canupdatelast,
                    canupdateaddr1: data.canupdateaddr1,
                    canupdateaddr2: data.canupdateaddr2,
                    canupdateaddr3: data.canupdateaddr3,
                    canupdatepostcode: data.canupdatepostcode,
                    canupdatephone: data.canupdatephone,
                    canupdatephone1: data.canupdatephone1,
                    canupdatemobile: data.canupdatemobile,
                    canupdatehomephone: data.canupdatehomephone,
                    canupdateworkphone: data.canupdateworkphone,
                    canupdateemail: data.canupdateemail,
                    canupdateuserfields: data.canupdateuserfields
                } 
            }
            console.log(`in Logon`)
            console.log(loggedon)
            // Send to Redux Store.
            dispatch(addauth(loggedon)) 

            // Update the caller 
            props.onChange({loggedon: true, ...loggedon })
            return
        }
    }

    return(
        <div className={classes.logonForm}>
            <img src={logo} alt='Edwards & Power' className={classes.logo} width="150" /><br/>

            <InputLabel className={classes.label} id="lbluserName">Username</InputLabel>
            <Input id="username" name="username" value={username} onChange={(e) => setUsername(e.target.value)} labelid="lbluserName" />
            <InputLabel className={classes.label} id="lblPassword">Password</InputLabel>
            <Input id="password" name="password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} labelid="lblPassword" />
            <br/>&nbsp;<br/>
            <StyledButton variant="contained"  onClick={DoLogon} >Logon</StyledButton>
            <p>{error}</p>
        </div>
    )
}

export default withStyles(styles)(Logon)